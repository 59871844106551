import './App.css';
import MovieBooking from './MovieBooking';

function App() {
  return (
    <div className="body">
        <MovieBooking/>
    </div>
  );
}

export default App;
